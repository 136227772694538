import {
  compose,
  filter,
  map,
  propOr,
  prop,
  dissoc,
  find,
  propEq,
  sortBy,
  path,
  pathOr,
  is,
  flatten,
  isNil,
  isEmpty,
  last,
  split,
  dropLast,
  join,
  values,
  pluck,
  head,
  curry,
  reduce,
  assoc,
  keys
} from 'ramda'
import { useEffect } from 'react'
import queryString from 'query-string'
import moment from 'moment-timezone'
import { isBlank } from './isBlank'
import { getPropertyFromLocalStorage } from './storage'
import {
  DATE_RANGE,
  DIAL_CODE_MAPPER,
  countryMapper,
  towProviderFlowIncludedReasons
} from '../constants'
import { stampLogs } from './logs'
import { v4 as uuid } from 'uuid'

const STACK = process.env.REACT_APP_STACK || 'c'

window.requestIdleCallback =
  window.requestIdleCallback ||
  function (cb) {
    var start = Date.now()
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start))
        }
      })
    }, 1)
  }

window.cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    clearTimeout(id)
  }

/**
 *
 * @param {string} userType
 * @param {<Object>} reasons
 * @param {<Object>} userTypes
 */
export const getFilteredReasons = (
  userType = '',
  reasons = [],
  userTypes = []
) => {
  const omitProperties = (obj) => dissoc('user_type', obj)
  const userTypeCodes = userTypes.map(prop('key'))
  const filterReasonsByUser = filter((obj) =>
    userTypeCodes.includes(userType)
      ? propOr([], 'user_type', obj).includes(userType)
      : true
  )
  return compose(map(omitProperties), filterReasonsByUser)(reasons)
}

export const getReasonsForUserType = (userType = '', reasons = []) => {
  const omitProperties = (obj) => dissoc('user_type', obj)
  const filterReasonsByUser = filter((obj) =>
    obj['user_type'].includes(userType)
  )
  return compose(map(omitProperties), filterReasonsByUser)(reasons)
}

/**
 *
 * @param {string} state
 * @param {<Object>} facilities
 * @param {<Object>} states
 * @param {string} stack
 */
export const getFilteredYards = (
  state = '',
  facilities = [],
  states = [],
  stack = 'c'
) => {
  if (stack === 'uk') {
    return facilities
  }
  const statesCodes = states.map(prop('key'))
  const filterFacilitiesByState = filter((obj) =>
    statesCodes.includes(state)
      ? propOr([], 'state', obj).includes(state)
      : false
  )
  return compose(filterFacilitiesByState)(facilities)
}

/**
 *
 * @param {string} type
 * @param {<Object>} referenceData
 */
export const parseReferenceData = (type, referenceData = []) => {
  switch (type) {
    case 'user_types':
      const userTypes = referenceData.map(({ code, desc, order }) => ({
        key: code,
        text: desc,
        value: code,
        order
      }))
      return sortBy(prop('order'))(userTypes)
    case 'states':
      return referenceData.map(({ code, desc, ...other }) => ({
        key: code,
        text: STACK === 'c' ? `${code} - ${desc}` : code,
        value: code,
        ...other
      }))
    case 'facilities':
    case 'reasons':
      const reasons = referenceData.map(({ code, desc, ...other }) => ({
        key: code,
        text: desc,
        value: code,
        ...other
      }))
      return sortBy(prop('order'))(reasons)
    case 'truck_types':
      const truckTypes = referenceData.map(({ code, description }) => ({
        key: code,
        text: description,
        value: description
      }))
      return truckTypes
    default:
      console.log('Invalid reference type')
      return referenceData
  }
}

/**
 *
 * @param {string} code
 */
export const getReferenceDataDescription = (code, referenceData) => {
  const data = find(propEq('key', code))(referenceData)
  return propOr(code, 'text', data)
}

export const yesNoOptions = [
  { key: 'Y', text: 'Yes', value: 'Y' },
  { key: 'N', text: 'No', value: 'N' }
]

export const numberOfLotsOptions = new Array(10)
  .fill({ key: '1' })
  .map((o, index) => ({ key: index + 1, value: index + 1, text: index + 1 }))

const formatFacilityOptions = ({
  yard_number: code = '',
  yard_name: name = '',
  yard_state_code: state = '',
  facility_type_code: facilityType = 'Y'
}) => ({
  code,
  desc: facilityType === 'S' ? name : `${name} - ${code}`,
  state
})

export const constructFacilityData = (response = {}, stack) => {
  let formattedFacilityData = []
  const facilityData = pathOr([], ['data', 'data'], response)
  if (isBlank(facilityData)) {
    return {}
  }
  const mapViewData = facilityData.reduce((acc, currVal) => {
    formattedFacilityData.push(formatFacilityOptions(currVal))

    const {
      yard_number: yardNumber,
      yard_state_code: yardStateCode,
      yard_name: yardName = '',
      yard_address1: addressLine1 = '',
      yard_address2: addressLine2 = '',
      yard_city: city = '',
      yard_zip: zip = '',
      yard_location_0_coordinate: yardLat = '',
      yard_location_1_coordinate: yardLong = '',
      facility_type_code: facilityTypeCode = 'Y'
    } = currVal

    const locationData = {
      [yardNumber]: {
        yardNumber,
        yardStateCode,
        yardName,
        yardLat,
        yardLong,
        facilityTypeCode,
        addressLine1,
        addressLine2,
        city,
        zip
      }
    }

    const mergeObj =
      stack === 'c'
        ? {
            [yardStateCode]: {
              ...(acc[yardStateCode] || {}),
              ...locationData
            }
          }
        : {
            ...locationData
          }

    return {
      ...acc,
      ...mergeObj
    }
  }, {})

  return {
    mapViewData,
    formattedFacilityData
  }
}

export const formatAddress = (data = {}) => {
  if (isBlank(data)) {
    return {
      addressSec1: '',
      addressSec2: ''
    }
  }
  const {
    yardStateCode = '',
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    zip = ''
  } = data
  return {
    addressSec1: `${addressLine1}${
      addressLine2 ? ', ' : ''
    }${addressLine2}`.trim(),
    addressSec2: `${city}, ${yardStateCode} ${zip}`
  }
}

export const getMode = () => {
  const search = propOr('', 'search', window.location)
  return propOr('prod', 'mode', queryString.parse(search))
}

export const skipGeoFencing = () => {
  const search = propOr('', 'search', window.location)
  return propOr(false, 'skipgeofence', queryString.parse(search))
}

export const getYardFromStorage = () => sessionStorage.getItem('yardFromUrl')

export const getYardFromUrl = () => {
  const search = propOr('', 'search', window.location)
  return propOr(getYardFromStorage(), 'yard', queryString.parse(search))
}

export const getDefaultCountry = () => {
  switch (STACK) {
    case 'c':
      return window.location.hostname.includes('.com') ? 'us' : 'ca'
    default:
      return 'gb'
  }
}

export const convertDateToYardTimeZone = (date, yardTimeZone) => {
  return date.tz(yardTimeZone)
}

const defaultCountry = STACK === 'c' ? 'USA' : 'GBR'

export const getCountryCode = () => {
  const countryCode = getDefaultCountry()
  return countryMapper[countryCode] || defaultCountry
}

export const stateMatchingConditionCheck = (state, matchingStates) => {
  return is(Array, matchingStates)
    ? matchingStates.some((matchingState) => state.matches(matchingState))
    : state.matches(matchingStates)
}

export const transformSlotData = ({ schedules, holidays }, timeZone) => {
  let appointments = {}

  for (let n = 0; n < DATE_RANGE; n += 1) {
    const nthday = convertDateToYardTimeZone(moment(), timeZone)
      .add(n, 'days')
      .format('YYYY-MM-DD')
    appointments[nthday] = []
  }

  const sortedData = schedules?.sort((a, b) => {
    if (a.start_datetime < b.start_datetime) {
      return -1
    }
    if (a.start_datetime > b.start_datetime) {
      return 1
    }
    return 0
  })
  sortedData.forEach((slot) => {
    const startDate = convertDateToYardTimeZone(
      moment(slot.start_datetime),
      timeZone
    ).format('YYYY-MM-DD')
    const startTime = convertDateToYardTimeZone(
      moment(slot.start_datetime),
      timeZone
    ).format('h:mm A')
    /* const endTime = convertDateToYardTimeZone(
      moment(slot.end_datetime),
      timeZone
    ).format('h:mm A') */
    const obj = {
      schedule_id: slot.facility_schedule_id,
      slot: startTime,
      startDateTime: convertDateToYardTimeZone(
        moment(slot.start_datetime),
        timeZone
      ),
      capacity: slot.capacity,
      scheduled_lots: slot.scheduled_lots
    }
    appointments[startDate] &&
      !holidays.find((holiday) => holiday.date === startDate) &&
      appointments[startDate].push(obj)
  })

  return appointments
}

export const mapPickupFacility = (yardData) => {
  const facilityObj = dissoc('address', { ...yardData.address, ...yardData })
  const keysMap = {
    address_line_1: 'addressLine1',
    address_line_2: 'addressLine2',
    postal_code: 'zip',
    state_code: 'yardStateCode',
    facility_id: 'yardNumber',
    facility_name: 'yardName',
    facility_type_code: 'facilityTypeCode',
    latitude: 'yardLat',
    longitude: 'yardLong',
    sublot_code: 'sublotCode'
  }
  const renameKeys = curry((keysMap, obj) =>
    reduce(
      (acc, key) => assoc(keysMap[key] || key, obj[key], acc),
      {},
      keys(obj)
    )
  )
  return renameKeys(keysMap, facilityObj)
}
export const mapPickupLocationForSummary = (yardData) => {
  const facilityObj = dissoc('address', { ...yardData.address, ...yardData })
  const keysMap = {
    address_line_1: 'yard_address1',
    address_line_2: 'yard_address2',
    postal_code: 'yard_zip',
    state_code: 'yard_state_code',
    facility_id: 'yard_number',
    facility_name: 'yard_name',
    facility_type_code: 'facilityTypeCode',
    latitude: 'yard_lat',
    longitude: 'yard_long',
    sublot_code: 'sublotCode'
  }
  const renameKeys = curry((keysMap, obj) =>
    reduce(
      (acc, key) => assoc(keysMap[key] || key, obj[key], acc),
      {},
      keys(obj)
    )
  )
  return renameKeys(keysMap, facilityObj)
}

export const mapLotData = (serverLotDetails, lotItems) => {
  return Object.entries(serverLotDetails).map(([lotKey, obj]) => {
    const isCopartDelivery = lotItems.find(
      (lot) => lot.lot_number === lotKey
    )?.isCopartDelivery
    if (obj.status === 'error') {
      let errors = obj.errors
      const errorsKeys = Object.keys(errors)

      if (errorsKeys.includes('lot_code')) {
        // Custom gate pass pin error message
        errors = { ...errors, lot_code: 'Invalid Gate Pass PIN' }
      }
      const errorsArray = Object.values(errors)

      const flattenedError = flatten(errorsArray)
      return { lot_number: lotKey, errors: flattenedError }
    } else {
      if (lotItems.find((lot) => lot.lot_number === lotKey)) {
        // if lotnumber matches with lotKey
        const reasons = lotItems.find(
          (lot) => lot.lot_number === lotKey
        )?.reasons
        const isCopartDelivery = lotItems.find(
          (lot) => lot.lot_number === lotKey
        )?.isCopartDelivery
        return {
          lot_number: lotKey,
          reasons,
          isCopartDelivery,
          member_number: obj.buyerNumber,
          ...obj
        }
      } else if (
        lotItems.find(
          (lot) => lot.gate_pass_pin.toString() === lotKey.toString()
        )
      ) {
        // if gatepass pin matches with lotKey
        const reasons = lotItems.find(
          (lot) => lot.gate_pass_pin === lotKey
        )?.reasons
        const isCopartDelivery = lotItems.find(
          (lot) => lot.gate_pass_pin === lotKey
        )?.isCopartDelivery
        return {
          lot_number: obj.lotNumber,
          reasons,
          isCopartDelivery,
          member_number: obj.buyerNumber,
          gate_pass_pin: lotKey,
          ...obj
        }
      }
      return {}
    }
  })
}

export const getSource = () => {
  if (getPropertyFromLocalStorage('facilityId')) return 'Kiosk'
  return 'Mobile'
}

const splitFullName = (name) => {
  const nameArray = split(' ')(name)
  if (nameArray.length > 1) {
    const firstName = join(' ', dropLast(1, nameArray))
    const lastName = last(nameArray)
    return { firstName, lastName }
  }
  return { firstName: name, lastName: '' }
}

export const transformAppointmentData = (appointmentData, timeZone) => {
  const startTime = convertDateToYardTimeZone(
    moment(appointmentData?.data?.start_datetime),
    timeZone
  )
  const appointmentDate = `${startTime.format('MM/DD/YYYY')} ${startTime.format(
    'h:mm A'
  )} - ${convertDateToYardTimeZone(
    moment(appointmentData?.data?.end_datetime),
    timeZone
  ).format('h:mm A')}`
  const name = splitFullName(appointmentData?.transporter_details?.name)

  const obj = {
    firstName: name?.firstName,
    lastName: name?.lastName,
    appointmentDate,
    lotDetails: [],
    startTime,
    endTime: appointmentData?.data?.end_datetime,
    facilityId: appointmentData?.data?.facility_id,
    stateCode: appointmentData?.transporter_details?.state,
    transporterId: appointmentData?.transporter_details?.id,
    appointmentHeaderId: appointmentData?.data?.appointment_header_id,
    appointmentStatus: appointmentData?.data?.appointment_status,
    timeZone
  }

  appointmentData.data.appointment_details.forEach((item) => {
    obj.lotDetails.push({
      lotNumber:
        path(['loader_bundle_activity', 'lot_number'], item) ||
        path(['lot', 'lot_number'], item),
      lotStatus: path(['loader_bundle_activity', 'loader_bundle_status'], item),
      hasKeys: path(['lot', 'has_keys'], item),
      titleStatus: path(['lot', 'title_status'], item),
      dueAmount: path(['lot', 'amount_due'], item),
      billPaid: path(['lot', 'billPaid'], item),
      isCopartDelivery: path(
        ['loader_bundle_activity', 'copart_delivery'],
        item
      )
    })
  })

  return obj
}

export const mapTransporterAndAppointmentDetails = (
  data,
  reasons,
  timeZone
) => {
  const details = data?.transporter_details
  let transporterDetails = {}
  if (details) {
    const name = splitFullName(details.name)
    transporterDetails = {
      transporterId: details.id,
      firstName: name.firstName,
      lastName: name.lastName,
      licensePlateNumber: details.license_plate,
      // phoneNumber: details.phone_number,
      // stateCode: details.state,
      driversLicence: details.drivers_license,
      licenseState: details.license_state_code,
      vehicleDetails: details.vehicle_details,
      // facilityId: details.facility_id,
      registeredUser: details.registered_user
    }
  }

  const hasAppointment = !isNil(data?.data) && !isEmpty(data?.data)

  let appointmentDetails = []
  if (hasAppointment) {
    appointmentDetails = data?.data.map((appointment) => {
      const startTime = convertDateToYardTimeZone(
        moment(appointment.start_datetime),
        timeZone
      )
      const appointmentDate = convertDateToYardTimeZone(
        moment(appointment.start_datetime),
        timeZone
      ).format('ll')

      const lots = appointment.appointment_details.map((item) => {
        return {
          lotNumber: path(['loader_bundle_activity', 'lot_number'], item),
          memberId: path(['loader_bundle_activity', 'member_id'], item),
          dueAmount: path(['lot', 'amount_due'], item),
          billPaid: path(['lot', 'billPaid'], item),
          reasons
        }
      })
      return {
        appointmentHeaderId: appointment?.appointment_header_id,
        name: details.name,
        appointmentDate,
        lots,
        startTime,
        endTime: appointment.end_datetime,
        facilityId: appointment.facility_id,
        appointmentStatus: appointment.appointment_status,
        startDateTime: appointment.start_datetime
      }
    })
  }

  return {
    transporterDetails,
    appointmentDetails,
    hasAppointment
  }
}

const composeErrors = (errorMessage) => {
  if (is(Array, errorMessage)) {
    return errorMessage.map((message) => composeErrors(message))
  } else if (is(Object, errorMessage)) {
    if (errorMessage.code === 400 || errorMessage.code === 500)
      return compose(
        composeErrors,
        flatten,
        values
      )({ ...errorMessage, error_details: undefined, code: undefined })
    return compose(composeErrors, flatten, values)(errorMessage)
  } else if (is(String, errorMessage)) {
    return errorMessage
  }
}

export const parseErrorMessage = (errors) => {
  const defaultErrorMessage =
    'Service is currently down. Please try again later.'
  if (errors?.status >= 500) {
    return defaultErrorMessage
  }
  const errorMessage = errors?.data?.message
  return errorMessage
    ? compose(join(', '), filter(Boolean), flatten, composeErrors)(errorMessage)
    : defaultErrorMessage
}

export const getAddressByFacility = (mapData, facilityId) => {
  const isNotNull = (n) => !(isNil(n) || isEmpty(n))

  return compose(head, filter(isNotNull), values, pluck(facilityId))(mapData)
}

export const substitute = (template, obj = {}) => {
  return template.replace(/\${([a-z0-9_]+)}/gi, function (match, capture) {
    return obj[capture]
  })
}

export const mergeMeta = (meta) => {
  return Object.keys(meta).reduce((acc, key) => {
    const value = meta[key]

    // Assuming each meta value is an object
    Object.assign(acc, value)

    return acc
  }, {})
}

export const getNavigatorGeolocation = (
  successCallback,
  errorCallback = () => {},
  params = {}
) => {
  if (typeof navigator !== 'undefined' && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      successCallback,
      errorCallback,
      params
    )
  }
}

export const appendDialCode = (phoneNumber, countryCode = 'us') =>
  `${DIAL_CODE_MAPPER[countryCode]}${phoneNumber}`

/*
 * returns distance in MILES
 */
export const calculateDistance = (
  lotLat = 0,
  lotLng = 0,
  currentLat,
  currentLng
) => {
  const radlat1 = (Math.PI * lotLat) / 180
  const radlat2 = (Math.PI * currentLat) / 180
  const theta = lotLng - currentLng
  const radtheta = (Math.PI * theta) / 180
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = parseFloat(dist * 60 * 1.1515).toFixed(2)
  return dist
}
/*
 * default range is 1 mile
 */
export const checkWithRangeForYard = (
  currentPosition,
  yardPosition,
  range = 1
) => {
  const distance = calculateDistance(
    yardPosition.lat,
    yardPosition.long,
    currentPosition.lat,
    currentPosition.long
  )
  return range > distance
}

export const isTowProviderCheck = (userType, reasons, facilityId) => {
  return (
    (userType === 'TRNSPTR' || userType === 'MBR' || userType === 'PMRMBR') &&
    reasons.find((reason) => towProviderFlowIncludedReasons.includes(reason))
  )
}

export const useLogging = (service) => {
  useEffect(() => {
    // phonenumber, lotnumbers, reasons

    const subscription = service.subscribe((state) => {
      try {
        stampLogs(state)
      } catch (e) {
        console.error(e, 'logging failed')
      }
    })

    return subscription.unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const getDeviceId = () => {
  if (!localStorage.getItem('deviceId'))
    localStorage.setItem('deviceId', uuid())

  return localStorage.getItem('deviceId')
}

export const skipgeofence = () => {
  const res = getPropertyFromLocalStorage('skipgeofence')
  return !isNil(res) && !isEmpty(res)
}

export const showMoneyOrderMessage = () => {
  const dateRange = ['2024-03-01', '2024-03-26']
  const startDate = new Date(dateRange?.[0]).getTime()
  const endDate = new Date(dateRange?.[1]).getTime()
  const todayDate = new Date().getTime()
  return todayDate >= startDate && todayDate <= endDate
}

export const checkCopartDelivery = (lots) => {
  const validLots = lots.filter((lot) => lot.lot_number.length === 8)
  const copartDeliveryLots = validLots.filter((lot) => lot.isCopartDelivery)
  if (
    validLots.length === 0 ||
    (validLots.length !== 0 && copartDeliveryLots.length === 0)
  ) {
    return false
  }
  if (validLots.length === copartDeliveryLots.length) {
    return true
  }
  return 'error'
}
